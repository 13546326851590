import { useEffect, useMemo, useState } from 'react';
import {
    Carousel,
    Dropdown,
    Tabs,
    Typography,
    useBreakpoint,
} from '@decub8/ui';
import { GetServerSideProps } from 'next';
import { useRouter } from 'next/router';

import { api_client } from '@src/bootstrap';
import { Content, ContentSection } from '@src/components';
import ProjectCard from '@src/components/Project/ProjectCard';
import { StatisticsContainer } from '@src/components/StatisticsContainer';
import { createContractManager } from '@src/contracts/index';
import { useAppSelector } from '@src/hooks/index';
import { useGlobalContext } from '@src/hooks/useGlobalContext';
import { CONTENT_API } from '@src/services/content';
import { PROJECT } from '@src/services/project';
import {
    ContentSectionType,
    EventType,
    ProjectStatus,
    UserGroup,
} from '@src/ts/constants';
import {
    DisplayProject,
    FeaturedItem,
    IContentSection,
} from '@src/ts/interfaces';
import {
    getActiveIndex,
    getDateToUse,
    getEventStatus,
} from '@src/utils/getters';

const ProjectList: React.FC<{ children?: React.ReactNode }> = ({
    children,
}) => {
    return (
        <div
            className="grid grid-cols-1 sm:grid-cols-2 lg:grid-cols-3 gap-4 mb-12 items-stretch"
            data-testid="event-container"
        >
            {children}
        </div>
    );
};

const options = [
    { title: 'IDO events', id: EventType.Crowdfunding as string },
    { title: 'Learn to earn events', id: EventType.TokenClaim as string },
];

enum SortOrder {
    Newest = 'Newest',
    Oldest = 'Oldest',
}

const Home: React.FC<{
    mapped_projects: DisplayProject[];
    featured_items: FeaturedItem[];
    landing_page_video: IContentSection;
    partner_section: IContentSection;
}> = ({
    mapped_projects,
    featured_items,
    landing_page_video,
    partner_section,
}) => {
    const [projects, setProjects] = useState(mapped_projects);
    const [nav, setNav] = useState(options[0].id);
    const [sort_order, setSortOrder] = useState(SortOrder.Newest);

    const router = useRouter();
    const { user } = useAppSelector((state) => state.auth);
    const { contract_manager } = useGlobalContext();

    const breakpoint = useBreakpoint();

    const { crowdfunding_events, token_claim_events } = useMemo(() => {
        const crowdfunding_events = [];
        const token_claim_events = [];

        projects?.forEach((project) => {
            const [event] = project.events;
            if (event.type === EventType.Crowdfunding) {
                crowdfunding_events.push(project);
            } else if (event.type === EventType.TokenClaim) {
                token_claim_events.push(project);
            }
        });

        return { crowdfunding_events, token_claim_events };
    }, [mapped_projects]);

    // Determine which array of projects to use based on the selected tab
    const events_to_display =
        nav === EventType.Crowdfunding
            ? crowdfunding_events
            : token_claim_events;

    const sorted_events = useMemo(() => {
        const sorted_by_status_and_date = events_to_display
            .filter(
                (project) =>
                    UserGroup.ProjectManager.includes(user?.role) ||
                    project.status === ProjectStatus.ACTIVE,
            )
            .sort((a, b) => {
                // First, sort by status priority
                const status_diff = getActiveIndex(a) - getActiveIndex(b);

                if (status_diff !== 0) return status_diff;

                // Then sort by the latest start date from events if status is the same
                const date_A = getDateToUse(a);
                const date_B = getDateToUse(b);

                return sort_order === SortOrder.Newest
                    ? date_B - date_A
                    : date_A - date_B;
            });

        return sorted_by_status_and_date;
    }, [user, events_to_display, sort_order]);

    const sort_options = [
        {
            name: 'Newest',
            onOptionClick: () => setSortOrder(SortOrder.Newest),
        },
        {
            name: 'Oldest',
            onOptionClick: () => setSortOrder(SortOrder.Oldest),
        },
    ];

    useEffect(() => {
        if (!user) return;

        // Get the event status for each project - used for sorting events & giving user feedback on the status of the event
        Promise.all(
            mapped_projects.map(async (p) => {
                const {
                    events: [event],
                } = p;

                const event_status = await getEventStatus(
                    event,
                    p.announced,
                    contract_manager,
                    user.id,
                );

                return {
                    ...p,
                    event_status,
                };
            }),
        )
            .then((projects) => {
                setProjects(projects);
            })
            .catch((err) =>
                console.error('Error while getting updated project chips', err),
            );
    }, [contract_manager, user]);

    return (
        <Content padding={false}>
            {!landing_page_video ||
                (!landing_page_video?.hidden && (
                    <ContentSection section={landing_page_video} />
                ))}

            <div className="px-5">
                {!partner_section ||
                    (!partner_section.hidden && (
                        <ContentSection
                            section={partner_section}
                            className="my-[60px]"
                        />
                    ))}

                <Carousel
                    items={featured_items
                        .map((i) => ({
                            ...i,
                            handleClick: () =>
                                i.link.includes('http')
                                    ? window.open(i.link)
                                    : router.push(i.link),
                        }))
                        .filter(
                            (item) => item.title !== '' && item.subtitle !== '',
                        )}
                    className={`mt-5 mb-16 h-full ${
                        !partner_section?.hidden ? '' : 'mt-8'
                    }`}
                />
                {/* ELEMENT USED FOR SCROLLING TO - DON'T DELETE */}
                <div id="events"></div>
                <Tabs
                    className="max-w-[380px] mt-8"
                    options={options}
                    current={nav}
                    setCurrent={setNav}
                />
                <StatisticsContainer eventType={nav as EventType} />
                <div className="flex items-center justify-between mt-8 mb-5">
                    <Typography size="3xl" allBold>
                        {options.find((option) => option.id === nav).title}
                    </Typography>

                    <div className="flex items-center space-x-5 justify-between">
                        {breakpoint > 450 && <Typography>Sort by</Typography>}
                        <Dropdown
                            accent_icon
                            id="project-page-sort"
                            title={<span className="mx-3">{sort_order}</span>}
                            options={sort_options}
                            container_class="z-30"
                            dropdown_width=""
                        />
                    </div>
                </div>
                <ProjectList>
                    {sorted_events.map((p, idx) => (
                        <ProjectCard
                            key={`${p.id}-${idx}-${p.events[0]?.name}`}
                            {...p}
                        />
                    ))}
                </ProjectList>
            </div>
        </Content>
    );
};

export const getServerSideProps: GetServerSideProps = async () => {
    try {
        const contract_manager = createContractManager();

        // Fetch all projects and home page content
        const [
            {
                retrieveProjects: { data: projects },
            },
            { getFeaturedItems: featured_items },
            { retrieveContentSection: landing_page_video },
            { retrieveContentSection: partner_section },
        ] = await Promise.all([
            api_client.query<{ retrieveProjects: { data: DisplayProject[] } }>({
                query: PROJECT.GET_LIST,
                variables: { limit: 100 },
                fetchPolicy: 'network-only',
            }),
            api_client.query<{ getFeaturedItems: FeaturedItem[] }>({
                query: PROJECT.GET_FEATURED_ITEMS,
                fetchPolicy: 'network-only',
            }),
            api_client
                .query<{ retrieveContentSection: ContentSectionType }>({
                    query: CONTENT_API.RETRIEVE_CONTENT_SECTION,
                    fetchPolicy: 'network-only',
                    variables: {
                        name: ContentSectionType.LandingPageVideo,
                    },
                })
                // handling null content section
                .catch(() => ({ retrieveContentSection: null })),
            api_client
                .query<{ retrieveContentSection: ContentSectionType }>({
                    query: CONTENT_API.RETRIEVE_CONTENT_SECTION,
                    fetchPolicy: 'network-only',
                    variables: {
                        name: ContentSectionType.PartnerSection,
                    },
                })
                // handling null content section
                .catch(() => ({ retrieveContentSection: null })),
        ]);

        // Created a new array of projects with each event as a separate project
        const reduced_projects = projects.reduce((acc, p) => {
            if (p.events?.length) {
                const project_events = [];
                for (const e of p.events) {
                    const events = [
                        {
                            ...e,
                            event_details: {
                                ...(e.event_details || {}),
                                durations: e.event_details?.durations || [
                                    3600 * 22,
                                    3600,
                                    3600,
                                ],
                            },
                        },
                    ];
                    project_events.push({ ...p, events });
                }
                return [...acc, ...project_events];
            } else {
                return acc;
            }
        }, []);
        // Get the event status for each project - used for sorting events & giving user feedback on the status of the event
        const mapped_projects = await Promise.all(
            reduced_projects.map(async (p) => {
                const {
                    events: [event],
                } = p;

                const event_status = await getEventStatus(
                    event,
                    p.announced,
                    contract_manager,
                );

                return {
                    ...p,
                    event_status,
                };
            }),
        );
        return {
            props: {
                mapped_projects,
                featured_items,
                landing_page_video,
                partner_section,
            },
        };
    } catch (e) {
        return {
            props: {
                featured_items: [],
                mapped_projects: [],
            },
        };
    }
};

export default Home;
